.mapFolder {
	padding: 0px;
	margin: 0px;
	width: 100%;
	height: 20px;
	background-color: white;
	border-radius: 0px;
	border-style: none;
	border-width: 0px;
	border-color: white;
	text-align: center;
	display: flex;
	justify-content: right;
	align-items: center;
}

.mapMain {
	display: flex;
	justify-content: left;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: space-around;
	vertical-align: middle;
}

.mapLink {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	color: #085784;
	font-weight: bold;
	cursor: pointer;
}