.Folder {
	padding: 20px;
	margin: 20px;
	width: 200px;
	height: 70px;
	background-color: #f1f0ef;
	border-radius: 10px;
	border-style: dashed;
	border-width: 1px;
	border-color: black;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;

}

.Main {
	display: flex;
	justify-content: left;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: space-around;
	vertical-align: middle;
}