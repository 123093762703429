.App {
	padding: 20px;
	width: 250px;
	height: 80px;
	background-color: #f1f0ef;
	border-radius: 10px;
	border-style: dashed;
	border-width: 1px;
	border-color: black;
}

.icone {
	color: aqua;
	width: 250px;
	height: 80px;
}

.texto {
	text-align: center;
	width: 250px;
	height: 80px;
}